<template>
  <div class="login-container">
    <div class="login-window">
      <img src="/static/logo.svg" class="sz-login-logo" />
      <a-form
        layout="horizontal"
        :wrapper-col="{ span: 24 }"
        :model="formState"
        @finish="handleSubmit"
      >
        <a-form-item>
          <a-input
            v-model:value="formState.username"
            placeholder="Username"
            class="full-width-form-item"
          >
            <template #prefix><UserOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input v-model:value="formState.password" type="password" placeholder="Password">
            <template #prefix><LockOutlined style="color: rgba(0, 0, 0, 0.25)" /></template>
          </a-input>
        </a-form-item>
        <!-- <a-space align="start">
          <a-form-item>
            <a-checkbox v-model:checked="formState.rememberMe">Remember me</a-checkbox>
          </a-form-item>
          <a-form-item>
            <a>Forget password</a>
          </a-form-item>
        </a-space> -->
        <a-form-item>
          <a-button
            class="sz-signin"
            html-type="submit"
            type="primary"
            :disabled="formState.username === '' || formState.password === ''"
            :loading="isVerifying"
          >
            Sign in
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <div class="login-footer">
      Copyright © 2021<br />
      <a href="https://defence.gov.au/">Australian Department of Defence</a><br />
      <a href="https://www.dst.defence.gov.au/">Defence Science &amp; Technology Group</a><br />
      frontend: {{ UI_VERSION }}<br />
      backend: {{ backendVersion }}<br />
      <DefenceSvg class="defence-logo" />
    </div>
  </div>
</template>

<script lang="ts">
import { LockOutlined, UserOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import moment from 'moment'
import { computed, defineComponent, reactive, toRaw, UnwrapRef } from 'vue'
import { useRequest } from 'vue-request'

import DefenceSvg from '@/assets/department-of-defence-australia-logo.svg'
import { MESSAGE } from '@/constants/message'
import { ModuleNames } from '@/constants/vuex'
import { getInfo } from '@/services/api/system'
import { useStore } from '@/store'
import { AuthActionEnum } from '@/store/enums/actions/auth'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { vuexActions } from '@/store/util'
import type { LoginFormValue } from '@/types'

const UI_VERSION = process.env.VUE_APP_UI_VERSION

export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined,
    DefenceSvg
  },
  setup() {
    const store = useStore()
    const { data: dataInfo } = useRequest(getInfo)

    const backendVersion = computed(() =>
      dataInfo.value
        ? dataInfo.value.build?.version +
          ' ' +
          dataInfo.value.git?.commit?.id +
          ' ' +
          moment(dataInfo.value.git?.commit?.time).format('YY-MM-DD|HH:MM')
        : ''
    )

    const isVerifying = computed(() => store.state[ModuleNames.AUTH][AuthStateEnum.IS_VERIFYING])
    const formState: UnwrapRef<LoginFormValue> = reactive({
      username: '',
      password: '',
      rememberMe: true
    })

    /**
     * Handle login
     */
    const handleSubmit = async () => {
      try {
        await store.dispatch(
          vuexActions(ModuleNames.AUTH, AuthActionEnum.AUTH_LOGIN),
          toRaw(formState)
        )
      } catch (_e) {
        message.error(MESSAGE.INVALID_CREDENTIAL)
      }
    }

    return {
      backendVersion,
      UI_VERSION,
      formState,
      handleSubmit,
      isVerifying
    }
  }
})
</script>

<style lang="stylus">
.login-logo
  font-weight bold
  font-size 30px
  text-align center

.login-container
  position absolute
  right 120px
  bottom 60px
  width 400px

.login-window
  background-color white
  box-shadow 0 0 6px rgba(0, 0, 0, 0.3), 0 0 10px rgba(0, 0, 0, 0.6)
  border-radius 10px
  padding 20px
  display flex
  flex-direction column
  align-items center
  .ant-form
    width 100%
    .ant-form-item
      width 100%
      margin-bottom 10px

.login-footer
  margin-top 20px
  text-align center
  color white
  font-size 14px
  line-height 18px
  a
    color: #88d0ff;

.defence-logo
  margin-top 20px
  color white
  width 200px

.sz-login-logo
  height 60px
  margin 0 auto 30px auto

.sz-signin
  width 100%
</style>
