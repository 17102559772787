import { render } from "./department-of-defence-australia-logo.svg?vue&type=template&id=847184de"
const script = {}
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "847184de"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('847184de', script)) {
    api.reload('847184de', script)
  }
  
  module.hot.accept("./department-of-defence-australia-logo.svg?vue&type=template&id=847184de", () => {
    api.rerender('847184de', render)
  })

}

script.__file = "src/assets/department-of-defence-australia-logo.svg"

export default script