
import { LockOutlined, UserOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import moment from 'moment'
import { computed, defineComponent, reactive, toRaw, UnwrapRef } from 'vue'
import { useRequest } from 'vue-request'

import DefenceSvg from '@/assets/department-of-defence-australia-logo.svg'
import { MESSAGE } from '@/constants/message'
import { ModuleNames } from '@/constants/vuex'
import { getInfo } from '@/services/api/system'
import { useStore } from '@/store'
import { AuthActionEnum } from '@/store/enums/actions/auth'
import { AuthStateEnum } from '@/store/enums/states/auth'
import { vuexActions } from '@/store/util'
import type { LoginFormValue } from '@/types'

const UI_VERSION = process.env.VUE_APP_UI_VERSION

export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined,
    DefenceSvg
  },
  setup() {
    const store = useStore()
    const { data: dataInfo } = useRequest(getInfo)

    const backendVersion = computed(() =>
      dataInfo.value
        ? dataInfo.value.build?.version +
          ' ' +
          dataInfo.value.git?.commit?.id +
          ' ' +
          moment(dataInfo.value.git?.commit?.time).format('YY-MM-DD|HH:MM')
        : ''
    )

    const isVerifying = computed(() => store.state[ModuleNames.AUTH][AuthStateEnum.IS_VERIFYING])
    const formState: UnwrapRef<LoginFormValue> = reactive({
      username: '',
      password: '',
      rememberMe: true
    })

    /**
     * Handle login
     */
    const handleSubmit = async () => {
      try {
        await store.dispatch(
          vuexActions(ModuleNames.AUTH, AuthActionEnum.AUTH_LOGIN),
          toRaw(formState)
        )
      } catch (_e) {
        message.error(MESSAGE.INVALID_CREDENTIAL)
      }
    }

    return {
      backendVersion,
      UI_VERSION,
      formState,
      handleSubmit,
      isVerifying
    }
  }
})
