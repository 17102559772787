
import { defineComponent } from 'vue'

import LoginForm from '@/components/login/index.vue'

export default defineComponent({
  components: {
    LoginForm
  }
})
