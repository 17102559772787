<template>
  <div class="sz-login-wrapper">
    <div class="sz-login-bg">
      <div class="container">
        <LoginForm />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import LoginForm from '@/components/login/index.vue'

export default defineComponent({
  components: {
    LoginForm
  }
})
</script>

<style lang="stylus">
@import '../styles/commons.styl';

.sz-login-wrapper
  @extend .fullcover
  background radial-gradient(#1B6D89, #273d46) // #74dfdf #1B6D89 #273d46 #d0a252
  background-size cover
  background-position 30% 30%

.sz-login-bg
  @extend .fullcover
  background url('/static/bg.svg')
  background-repeat: no-repeat
  background-size 220% 220%
  background-position 70% 70%

  .container
    display flex
    align-items center
    height 100%
    justify-content center

.sz-login-footer
  position absolute
  left 0
  bottom 10px
  right 0
  color white
  text-align center
</style>
