import { render } from "./index.vue?vue&type=template&id=70c98a68"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"

import "./index.vue?vue&type=style&index=0&id=70c98a68&lang=stylus"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "70c98a68"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('70c98a68', script)) {
    api.reload('70c98a68', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=70c98a68", () => {
    api.rerender('70c98a68', render)
  })

}

script.__file = "src/components/login/index.vue"

export default script